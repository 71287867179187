<template>
  <section class="modal--container auth--container" v-if="step === 1">
    <div class="title--text hide--in-mobile">
      {{ $t('loginRegister.register') }}
    </div>
    <!--    <social-media-auth />-->
    <!--    <hr class="separator" />-->
    <my-alert :variant="alertType" :show="showAlert">
      <div v-html="messageAlert"></div>
    </my-alert>
    <form id="loginForm" @submit.prevent="next">
      <div class="auth--input mb-3" :class="{ 'has-error': validation.hasError('fullName') }">
        <v-text-field
          :label="$t('loginRegister.fullname')"
          outlined
          :hide-details="true"
          v-model="fullName"
          name="full_name"
          class="basic--input"
        ></v-text-field>
        <span class="val-error" v-if="validation.hasError('fullName')">{{
          validation.firstError('fullName')
        }}</span>
      </div>
      <div class="auth--input mb-3" :class="{ 'has-error': validation.hasError('email') }">
        <v-text-field
          :label="$t('loginRegister.email')"
          outlined
          :hide-details="true"
          v-model="email"
          name="email"
          class="basic--input"
        ></v-text-field>
        <span class="val-error" v-if="validation.hasError('email')">{{
          validation.firstError('email')
        }}</span>
      </div>
      <div class="auth--input mb-3" :class="{ 'has-error': validation.hasError('phone') }">
        <client-only>
          <vue-tel-input
            id="phone"
            @input="phoneInput"
            :validCharactersOnly="true"
            defaultCountry="id"
            :onlyCountries="['id']"
            :inputOptions="inputOptions"
          />
        </client-only>
        <span class="val-error" v-if="validation.hasError('phone')">{{
          validation.firstError('phone')
        }}</span>
      </div>
      <div class="auth--input mb-3" :class="{ 'has-error': validation.hasError('password') }">
        <v-text-field
          :label="$t('loginRegister.pass')"
          outlined
          :hide-details="true"
          v-model="password"
          name="password"
          class="basic--input"
          :type="showPass ? 'text' : 'password'"
          :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="() => (showPass = !showPass)"
        ></v-text-field>
        <span class="val-error" v-if="validation.hasError('password')">{{
          validation.firstError('password')
        }}</span>
      </div>
      <div
        class="auth--input mb-3"
        :class="{ 'has-error': validation.hasError('confirmPassword') }"
      >
        <v-text-field
          :label="$t('loginRegister.confirmPass')"
          outlined
          :hide-details="true"
          v-model="confirmPassword"
          name="confirm_password"
          class="basic--input"
          :type="showConfirmPass ? 'text' : 'password'"
          :append-icon="showConfirmPass ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="() => (showConfirmPass = !showConfirmPass)"
        ></v-text-field>
        <span class="val-error" v-if="validation.hasError('confirmPassword')">{{
          validation.firstError('confirmPassword')
        }}</span>
      </div>
      <div class="auth--input mb-3" :class="{ 'has-error': validation.hasError('referralCode') }">
        <v-text-field
          :label="$t('loginRegister.referralCode')"
          outlined
          :hide-details="true"
          v-model="referralCode"
          name="referral_code"
          class="basic--input"
        ></v-text-field>
        <span class="val-error" v-if="validation.hasError('referralCode')">{{
          validation.firstError('referralCode')
        }}</span>
      </div>
      <div
        class="auth--input mb-3"
        :class="{ 'has-error': validation.hasError('selectedAgentType') }"
      >
        <multiselect
          name="agentType"
          id="agentType"
          v-model="selectedAgentType"
          :placeholder="$t('loginRegister.agentType')"
          :options="agentTypes"
          :searchable="true"
          :close-on-select="true"
          track-by="id"
          label="name"
          select-label=""
          deselect-label=""
        ></multiselect>
        <span class="val-error" v-if="validation.hasError('selectedAgentType')">{{
          validation.firstError('selectedAgentType')
        }}</span>
      </div>
      <div
        class="auth--input mb-3"
        :class="{ 'has-error': validation.hasError('selectedAgentOffice') }"
        v-show="selectedAgentType && selectedAgentType.id === 2"
      >
        <multiselect
          name="agentOffice"
          id="agentOffice"
          v-model="selectedAgentOffice"
          :placeholder="$t('loginRegister.agentOffice')"
          :options="agentOffices"
          :searchable="true"
          :close-on-select="true"
          track-by="id"
          label="name"
          select-label=""
          deselect-label=""
        >
          <template slot="option" slot-scope="{ option }">
            <div class="agent--ofice-option">
              <div class="option--img" v-if="option.logo_url">
                <img :src="option.logo_url" :alt="option.name" />
              </div>
              <div class="option--text">
                {{ option.name }}
              </div>
            </div>
          </template>
          <template slot="singleLabel" slot-scope="{ option }">
            <div class="agent--ofice-option">
              <div class="option--img" v-if="option.logo_url">
                <img :src="option.logo_url" :alt="option.name" />
              </div>
              <div class="option--text">
                {{ option.name }}
              </div>
            </div>
          </template>
        </multiselect>
        <span class="val-error" v-if="validation.hasError('selectedAgentOffice')">{{
          validation.firstError('selectedAgentOffice')
        }}</span>
        <div
          class="custom-control custom-checkbox my-2"
          style="width: fit-content; margin-left: 16px"
        >
          <input
            @click="noAgentOffice = !noAgentOffice"
            type="checkbox"
            class="custom-control-input"
            :checked="noAgentOffice"
            id="no-agent-office"
          />
          <label class="custom-control-label" for="no-agent-office">
            {{ $t('loginRegister.noAgentOffice') }}
          </label>
        </div>
      </div>
      <div class="auth--input mb-3" :class="{ 'has-error': validation.hasError('nib') }">
        <v-text-field
          :label="$t('loginRegister.nib')"
          outlined
          :hide-details="true"
          v-model="nib"
          name="nib"
          class="basic--input"
        ></v-text-field>
        <span class="val-error" v-if="validation.hasError('nib')">{{
          validation.firstError('nib')
        }}</span>
      </div>

      <div
        class="auth--input mb-3"
        :class="{ 'has-error': validation.hasError('selectedAreaSpec') }"
      >
        <multiselect
          name="areaSpec"
          id="areaSpec"
          v-model="selectedAreaSpec"
          :placeholder="$t('profile.areaSpec')"
          :options="areaSpecList"
          :searchable="true"
          :close-on-select="true"
          track-by="id"
          label="name"
          :multiple="true"
          select-label=""
          deselect-label=""
        ></multiselect>
        <span class="val-error" v-if="validation.hasError('selectedAreaSpec')">{{
          validation.firstError('selectedAreaSpec')
        }}</span>
      </div>

      <hr class="separator" />
      <button :disabled="isLoading" type="submit" class="btn btn-primary main--btn">
        {{ $t('loginRegister.register') }}
      </button>
    </form>

    <div class="bottom--part">
      <span class="mr-2">{{ $t('loginRegister.message2') }}</span>
      <a href="javascript:" @click="showModal('modal-login')" class="auth--link">{{
        $t('loginRegister.messageBold2')
      }}</a>
    </div>
  </section>
  <div v-else-if="step === 2">
    <SectionLoader :show="isLoading" />
    <otp :phone="phone" from="register" @verifyOtp="register"></otp>
  </div>
</template>

<script>
import { Validator } from 'simple-vue-validator';
import HelperMixin from '@/mixins/helpers';
// const SocialMediaAuth = () => import('@/components/auth/social-media-auth');
const SectionLoader = () => import('@/components/content-loading/section-loading');
const Otp = () => import('@/components/auth/otp');

import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import { mapState } from 'vuex';
import Multiselect from 'vue-multiselect';
require('vue-multiselect/dist/vue-multiselect.min.css');
const MyAlert = () => import('@/components/utils/my-alert.vue');

export default {
  props: ['formType', 'dismissable'],
  mixins: [HelperMixin],
  components: {
    // SocialMediaAuth,
    SectionLoader,
    Otp,
    VueTelInput,
    Multiselect,
    MyAlert,
  },
  data() {
    return {
      email: null,
      phone: null,
      password: null,
      confirmPassword: null,
      fullName: null,
      referralCode: null,
      selectedAgentType: null,
      selectedAgentOffice: null,
      noAgentOffice: false,
      isLoading: false,
      remember: false,
      showAlert: false,
      messageAlert: null,
      alertType: '',
      showPass: false,
      showConfirmPass: false,
      step: 1,
      nib: null,
    };
  },
  computed: {
    ...mapState({
      agentTypes: (state) => state.auth.agentTypes,
      areaSpecList: (state) => state.auth.areaSpecList,
      agentOffices: (state) => state.auth.agentOffices,
    }),
    inputOptions() {
      return {
        placeholder: this.$t('profile.placeholder.phone'),
      };
    },
    selectedAreaSpec: {
      get() {
        return this.$store.state.auth.selectedAreaSpec;
      },
      set(value) {
        this.$store.commit('auth/SET_SELECTED_AREA_SPEC', value);
      },
    },
  },
  validators: {
    email: {
      debounce: 250,
      validator: function (value) {
        return Validator.value(value)
          .required(this.$i18n.t('errors.loginRegister.email.required'))
          .email(this.$i18n.t('errors.loginRegister.email.invalid'))
          .maxLength(255, this.$i18n.t('errors.loginRegister.email.max', { maxChar: 255 }))
          .custom(async () => {
            const { data } = await this.$store.dispatch('global/checkEmail', value);
            if (data.exist) {
              return this.$i18n.t('errors.loginRegister.email.exist');
            }
          });
      },
    },
    password(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.loginRegister.password.required'))
        .minLength(8, this.$i18n.t('errors.loginRegister.password.min', { minChar: 8 }))
        .maxLength(255, this.$i18n.t('errors.loginRegister.password.max', { maxChar: 255 }));
    },
    'confirmPassword, password': function (confirmPassword, password) {
      if (this.submitted || this.validation.isTouched('confirmPassword')) {
        return Validator.value(confirmPassword)
          .required(this.$i18n.t('errors.loginRegister.confirmPass.required'))
          .match(password, this.$i18n.t('errors.loginRegister.confirmPass.notMatched'));
      }
    },
    fullName(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.loginRegister.fullName.required'))
        .maxLength(150, this.$i18n.t('errors.loginRegister.fullName.max', { maxChar: 150 }));
    },
    phone(value) {
      return Validator.value(value).required(this.$i18n.t('errors.phone.required'));
    },
    referralCode(value) {
      return Validator.value(value);
    },
    selectedAgentType(value) {
      return Validator.value(value).required(
        this.$i18n.t('errors.loginRegister.selectedAgentType.required'),
      );
    },
    selectedAgentOffice(value) {
      if (!this.noAgentOffice && this.selectedAgentType && this.selectedAgentType.id === 2) {
        return Validator.value(value).required(
          this.$i18n.t('errors.loginRegister.selectedAgentOffice.required'),
        );
      } else {
        return Validator.value(value);
      }
    },
    selectedAreaSpec: {
      validator(value) {
        return Validator.value(value).required(this.$i18n.t('errors.selectedAreaSpec.required'));
      },
    },
    nib(value) {
      return Validator.value(value).maxLength(
        100,
        this.$i18n.t('errors.loginRegister.nib.max', { maxChar: 100 }),
      );
    },
  },
  watch: {
    selectedAgentOffice(val) {
      if (val !== null) {
        this.noAgentOffice = false;
      }
      this.$validate();
    },
    noAgentOffice(val) {
      if (val === true) {
        this.selectedAgentOffice = null;
      }
      this.$validate();
    },
  },
  created() {
    // extend('check_email', {
    //   message: field => `${this.capitalize(field)} already registered. Please use another e-mail.`,
    //   validate: value =>
    //     this.$store.dispatch('global/checkEmail', value).then(({ data }) => ({
    //       valid: data.valid === true,
    //     })),
    // });
    if (this.$route.query.invite_member_partner_token) {
      this.disableEmail = true;
      this.getInvitationData(this.$route.query.invite_member_partner_token);
    }
  },
  async mounted() {
    this.validation.reset();
    this.selectedAreaSpec = [];
    await this.$store.dispatch('auth/getRegisterForm');
    // if (this.agentTypes && this.agentTypes.length > 0) {
    //   this.selectedAgentType = this.agentTypes[0];
    // }
  },
  methods: {
    async showModal(modalName) {
      this.$modal.hide('modal-register');
      if (modalName === 'modal-forget-pass') {
        this.$modal.show(modalName);
      } else {
        if (this.formType === 'page') {
          this.$router.push('/login');
        } else {
          this.$modal.show(modalName, { dismissable: this.dismissable });
        }
      }
    },
    phoneInput(phone, phoneObject) {
      if (phoneObject && phoneObject.number) {
        this.phone = phoneObject.number;
      } else {
        this.phone = null;
      }
    },
    async next() {
      try {
        this.isLoading = true;
        const isValid = await this.$validate();
        if (isValid) {
          await this.$store.dispatch('global/sendOtp', {
            phone: this.phone,
          });
          this.step = 2;
        }
        // eslint-disable-next-line no-empty
      } catch (e) {
      } finally {
        this.isLoading = false;
      }
    },
    async register(otp) {
      try {
        this.isLoading = true;
        const isValid = await this.$validate();
        if (isValid) {
          const { data } = await this.$store.dispatch('global/registerUser', {
            name: this.cleanInput(this.fullName),
            email: this.cleanInput(this.email),
            password: this.cleanInput(this.password),
            // password_confirmation: this.confirmPassword,
            // mobile: false,
            otp: otp,
            phone_number: this.phone,
            referral_code: this.cleanInput(this.referralCode),
            agent_type_id: this.selectedAgentType ? this.selectedAgentType.id : null,
            area_specialization_ids: this.selectedAreaSpec.map((e) => e.id),
            agent_office_id:
              !this.noAgentOffice &&
              this.selectedAgentType &&
              this.selectedAgentType.id === 2 &&
              this.selectedAgentOffice
                ? this.selectedAgentOffice.id
                : null,
            nib: this.cleanInput(this.nib),
          });
          console.log('register user', data.data);
          if (data.data.success) {
            this.messageAlert = this.$i18n.t('register.success');
            this.alertType = 'success';
            this.showAlert = true;
            if (this.formType !== 'page') {
              this.$modal.hide('modal-register');
            }
            await this.$swal(this.$t('general.success'), this.messageAlert, 'success');
            this.$router.replace('/login');
          } else {
            this.messageAlert = this.$i18n.t('register.formError');
            this.alertType = 'danger';
            this.showAlert = true;
          }
        } else {
          this.scrollToErrorSection(this.$el);
        }
      } catch (e) {
        console.log('ERROR REGISTER: ', e);
      } finally {
        this.isLoading = false;
      }
    },
    async getInvitationData(token_) {
      try {
        this.loading = true;
        const { data } = await this.$http.get('/api/auth/get_invitation_data', {
          params: {
            invite_member_partner_token: token_,
          },
        });
        this.email = data.data.emailInviteMember;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
